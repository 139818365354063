var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "refFormObserver",
    staticClass: "pt-1",
    attrs: {
      "tag": "div"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "tag": "div",
      "vid": "vidPaymentMethod",
      "rules": "required",
      "name": _vm.$t('errorNoti.columns.type')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var validate = _ref.validate,
          errors = _ref.errors;
        return [_c('b-form-group', {
          staticClass: "mb-0",
          attrs: {
            "state": errors[0] ? false : null
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.columns.type')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "placeholder": _vm.$t('errorNoti.phSelectNotiType'),
            "options": _vm.TYPE_OPTIONS,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          on: {
            "close": validate
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.errorNotiData.type,
            callback: function callback($$v) {
              _vm.$set(_vm.errorNotiData, "type", $$v);
            },
            expression: "errorNotiData.type"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.errorNotiData.type === 'CQT' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.errorNotiNo')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }], null, false, 1535821069)
  }, [_c('validation-provider', {
    attrs: {
      "tag": "div",
      "vid": "vidCompany",
      "rules": "required",
      "name": _vm.$t('errorNoti.errorNotiNo')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "maxlength": "255",
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.errorNotiData.errorCode,
            callback: function callback($$v) {
              _vm.$set(_vm.errorNotiData, "errorCode", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "errorNotiData.errorCode"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3746094028)
  })], 1)], 1) : _vm._e(), _vm.errorNotiData.type === 'CQT' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "errorNotiDate",
      "rules": "required|dateFormat"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "errorNotiDate"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "font-weight-bold"
              }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.errorNotiDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          staticClass: "form-control",
          attrs: {
            "name": "errorNotiDate",
            "state": errors[0] ? false : null,
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": new Date(1920, 0, 1),
            "max": new Date(),
            "show-decade-nav": "",
            "hide-header": "",
            "locale": "vi",
            "no-flip": ""
          },
          model: {
            value: _vm.errorNotiData.errorDate,
            callback: function callback($$v) {
              _vm.$set(_vm.errorNotiData, "errorDate", $$v);
            },
            expression: "errorNotiData.errorDate"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2816469169)
  })], 1) : _vm._e()], 1)], 1), _c('b-table', {
    staticStyle: {
      "margin-bottom": "0",
      "padding": "1em"
    },
    attrs: {
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-invoice-edit-gs border-bottom",
      "items": _vm.selectedAdd,
      "fields": _vm.tableColumnsAdd,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsAdd, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref4) {
          var label = _ref4.label;
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("errorNoti.gsColumns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "cell(orderNo)",
      fn: function fn(_ref5) {
        var index = _ref5.index;
        return [_c('div', {
          staticClass: "w-100 text-center pt-50"
        }, [_vm._v(" " + _vm._s(index + 1) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "18"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteRow(data.item);
            }
          }
        })], 1)];
      }
    }], null, true)
  }), _c('b-button', {
    on: {
      "click": _vm.showPopupInvoiceList
    }
  }, [_vm._v(" Select invoices ")]), _c('div', {
    staticClass: "d-flex justify-content-center justify-content-xl-end align-items-center flex-grow-1 flex-wrap"
  }, [_c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.preview')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.SaveErrorNoti
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.save')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.SignErrorNoti
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.saveAndSend')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.SignErrorNoti
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sign')) + " ")]), _c('b-button', {
    staticClass: "ml-1 mt-50",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.cancel')) + " ")])], 1), _c('InvoicePopupList', {
    attrs: {
      "fixed-invoice-types": [_vm.INVOICE_TYPE_VALUES.original, _vm.INVOICE_TYPE_VALUES.adjustment, _vm.INVOICE_TYPE_VALUES.replacement],
      "fixed-invoice-status": [_vm.INVOICE_STATUS_VALUES.released],
      "previous-selected-ids": _vm.selectedIds,
      "popup-title": "Chọn hoá đơn Đã phát hành"
    },
    on: {
      "selectInvoices": _vm.onAddInvoices
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }